import React, { Component } from "react";
import SectionTitleTwo from "../../component/Banner/SectionTitleTwo";
import TestimonialSliderTwo from "./TestimonialSliderTwo";

class TestimonialTwo extends Component {
  render() {
    return (
      <div className="testimonial_area_two" id="testimonial">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="testimonial_slider_info">
                <SectionTitleTwo
                  tCenter="text-center"
                  stitle="Satisfied Customers"
                  btitle="The World’s Busiest Trust Us"
                />
                <TestimonialSliderTwo />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TestimonialTwo;

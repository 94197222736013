import React, { Component } from "react";
import SectionTitleTwo from "../Banner/SectionTitleTwo";
import BlogTwoitems from "../Blog/BlogTwoitems";
import Reveal from "react-reveal/Reveal/";
class HiringProcess extends Component {
  render() {
    return (
      <section className="hiring_area bg_color" id="process">
        <div className="container">
          <SectionTitleTwo
            tCenter="text-center"
            stitle="Pricing"
            btitle="AVA makes the hiring process seamless"
          />
          <div className="step-line-container">
            <div className="step-line-1-container">
              <img
                src={require("./../../assets/images/hiring/one.svg")}
                alt=""
              />
              <img
                className="line-arrow-container"
                src={require("./../../assets/images/hiring/linearrow.svg")}
                alt=""
              />
            </div>
            <div className="step-line-2-container">
              <img
                src={require("./../../assets/images/hiring/two.svg")}
                alt=""
              />
              <img
                className="line-arrow-container"
                src={require("./../../assets/images/hiring/linearrow.svg")}
                alt=""
              />
            </div>
            <div className="step-line-3-container">
              <img
                src={require("./../../assets/images/hiring/three.svg")}
                alt=""
              />
              <img
                className="line-arrow-container"
                src={require("./../../assets/images/hiring/linearrow.svg")}
                alt=""
              />
            </div>
            <div className="step-line-4-container">
              <img
                src={require("./../../assets/images/hiring/four.svg")}
                alt=""
              />
            </div>
          </div>
          <div className="process-instruction-container">
            <div className="step-1-container">
              <div className="step-mobile-container">
                <img
                  src={require("./../../assets/images/hiring/one.svg")}
                  alt=""
                />
              </div>
              <div className="instruction-header">
                Fill up the assessment form
              </div>
              <div className="instruction-desc">
                Our tailored form ensures we capture all your requirements in
                under 3 mins
              </div>
            </div>
            <div className="step-2-container">
              <div className="step-mobile-container">
                <img
                  src={require("./../../assets/images/hiring/two.svg")}
                  alt=""
                />
              </div>
              <div className="instruction-header">
                Wait 48 hours for the magic
              </div>
              <div className="instruction-desc">
                We match your requirements with our pool of pre-vetted VA’s and
                share the best fit
              </div>
            </div>
            <div className="step-3-container">
              <div className="step-mobile-container">
                <img
                  src={require("./../../assets/images/hiring/three.svg")}
                  alt=""
                />
              </div>
              <div className="instruction-header">
                Interview shortlisted VA’s
              </div>
              <div className="instruction-desc">
                We connect you with 2 expert VA’s that you can interview (100%
                success rate currently)
              </div>
            </div>
            <div className="step-4-container">
              <div className="step-mobile-container">
                <img
                  src={require("./../../assets/images/hiring/four.svg")}
                  alt=""
                />
              </div>
              <div className="instruction-header">Pay as per use</div>
              <div className="instruction-header-without-text"></div>
              <div className="instruction-desc">
                Your VA’s start immediately and you will be billed only for the
                amount of time they actually work
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default HiringProcess;

import React, { Component } from "react";
import axios from "axios";
import Loader from "react-loader-spinner";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InlineWidget } from "react-calendly";

class QuestionsVa extends Component {
  state = {
    currentQuestion: 1,
    answeredQuestion: [],
    email: "",
    country: "",
    profileLink: "",
    timePeriod: "",
    writtenSkills: "",
    spokenSkills: "",
    specializations: [],
    isOtherOptionSelected: false,
    salary: "",
    toolsUsed: [],
    isCallingForApi: false,
  };

  handleNextBtnClick = () => {
    if (this.state.currentQuestion === 1) {
      this.handleUpdateState();
    }
    if (this.state.currentQuestion === 2) {
      if (this.validate()) {
        this.handleUpdateState();
      } else {
        this.notify("Please enter your email address");
      }
    }
    if (this.state.currentQuestion === 3) {
      if (this.state.country.length > 0) {
        this.handleUpdateState();
      } else {
        this.notify("Please enter your country name");
      }
    }
    if (this.state.currentQuestion === 4) {
      if (this.state.profileLink.length > 0) {
        this.handleUpdateState();
      } else {
        this.notify("Please enter profile link");
      }
    }
    if (this.state.currentQuestion === 5) {
      if (this.state.timePeriod.length > 0) {
        this.handleUpdateState();
      } else {
        this.notify("Please select at least one time-period");
      }
    }
    if (this.state.currentQuestion === 6) {
      // alert(this.state.language);
      if (this.state.writtenSkills.length > 0) {
        this.handleUpdateState();
      } else {
        this.notify("Please select at least one option");
      }
    }
    if (this.state.currentQuestion === 7) {
      if (this.state.spokenSkills.length > 0) {
        this.handleUpdateState();
      } else {
        this.notify("Please select at least one option");
      }
    }
    if (this.state.currentQuestion === 8) {
      if (this.state.specializations.length > 0) {
        this.handleUpdateState();
      } else {
        this.notify("Please select at least one option");
      }
    }
    if (this.state.currentQuestion === 9) {
      if (this.state.salary.length > 0) {
        this.handleUpdateState();
      } else {
        this.notify("Please fill the amount");
      }
    }
    if (this.state.currentQuestion === 10) {
      this.setState({ isCallingForApi: true });
      this.handleSubmitForm();
    }
    if (this.state.currentQuestion === 11) {
      this.setState({ currentQuestion: this.state.currentQuestion + 1 });
    }
  };

  handleSubmitForm = () => {
    let details = {
      email: this.state.email,
      country: this.state.country,
      upWorkLink: this.state.profileLink,
      vaExperience: this.state.timePeriod,
      wriittenEnglishRating: this.state.writtenSkills,
      spokenEnglishRating: this.state.spokenSkills,
      fieldsWithOnePlusXp: this.state.specializations.toString(),
      monthlyFreelancingIncome: this.state.salary,
      toolsUsedInPast: this.state.toolsUsed.toString(),
      sendResponseCopy: "yes",
    };
    const emailSample = {
      subject: "User Details for Sign up as a Virtual Assistant",
      content: JSON.stringify(details),
      receiver: "amar@ava.work",
    };
    const emailSampleForCustomer = {
      subject: "Sign up as a Virtual Assistant",
      content:
        "Thank you for your information.\nYou have been registered in our database and our team will be in touch for an assessment before we start connecting you with clients.\n\n\n Thanks & Regards \n Team AVA",
      receiver: this.state.email,
    };

    axios
      .post("https://backend.ava.work/addVa", details)
      .then((res) => this.handleApiResponse(res));
    axios.post("https://backend.ava.work/sendEmail", emailSample);
    axios.post("https://backend.ava.work/sendEmail", emailSampleForCustomer);
  };

  handleApiResponse = (res) => {
    console.log(res);
    if (res.status === 200) {
      localStorage.removeItem("answeredQuestionforva");
      this.setState({ currentQuestion: this.state.currentQuestion + 1 });
      this.setState({ isCallingForApi: false });
      this.setState({ answeredQuestion: [] });
      this.setState({ email: "" });
      this.setState({ country: "" });
      this.setState({ profileLink: "" });
      this.setState({ timePeriod: "" });
      this.setState({ specializations: [] });
      this.setState({ salary: "" });
      this.setState({ toolsUsed: [] });
    }
  };

  handleUpdateState = () => {
    this.setState({ currentQuestion: this.state.currentQuestion + 1 });
    this.handleUpdateAnsweredQuestionState();
  };

  handleSignupForm = () => {
    if (this.state.currentQuestion === 11) {
      this.setState({ currentQuestion: 1 });
    }
    this.props.hideModal();
  };

  handleSelectRadioButton = (e, questionNumber) => {
    const target = e.target;
    var value = target.value;
    if (questionNumber === "five") {
      console.log("five");
      if (target.checked) {
        this.setState({ timePeriod: value });
      }
    }
    if (questionNumber === "six") {
      console.log("six");
      if (target.checked) {
        this.setState({ writtenSkills: value });
      }
    }
    if (questionNumber === "seven") {
      console.log("seven");
      if (target.checked) {
        this.setState({ spokenSkills: value });
      }
    }
  };

  notify = (error) => {
    toast.error(error);
  };

  validate = () => {
    var isValid = true;
    if (this.state.country.length === 0) {
      isValid = false;
    }
    if (!this.state.email) {
      isValid = false;
    }
    if (this.state.email) {
      let re = /\S+@\S+\.\S+/;
      isValid = re.test(this.state.email);
    }
    return isValid;
  };

  handleUpdateAnsweredQuestionState = () => {
    let answeredQuestionArray = this.state.answeredQuestion;
    if (this.state.answeredQuestion.length === 0) {
      this.state.answeredQuestion.push(1);
      this.setState({ answeredQuestion: this.state.answeredQuestion });
    } else {
      let lastAnsweredQuestionNumber = answeredQuestionArray.slice(-1);
      this.state.answeredQuestion.push(lastAnsweredQuestionNumber + 1);
      this.setState({ answeredQuestion: this.state.answeredQuestion });
    }
    localStorage.setItem(
      "answeredQuestionforva",
      JSON.stringify(this.state.answeredQuestion)
    );
  };

  handleChangeEmail = (e) => {
    this.setState({ email: e.target.value });
    console.log(e.target.value);
  };

  handleChangeCountry = (e) => {
    this.setState({ country: e.target.value });
    console.log(e.target.value);
  };

  handleChangeSalary = (e) => {
    this.setState({ salary: e.target.value });
    console.log(e.target.value);
  };

  handleChangeProfileLink = (e) => {
    this.setState({ profileLink: e.target.value });
    console.log(e.target.value);
  };

  handleCheckedSpecializations = (e) => {
    const target = e.target;
    var value = target.value;
    if (target.checked) {
      this.state.specializations.push(value);
      this.setState({ specializations: this.state.specializations });
    } else {
      let index = this.state.specializations.indexOf(value);
      this.state.specializations.splice(index, 1);
      this.setState({ specializations: this.state.specializations });
    }
  };

  handleCheckedSoftwareTools = (e) => {
    const target = e.target;
    var value = target.value;
    if (target.checked) {
      this.state.toolsUsed.push(value);
      this.setState({ toolsUsed: this.state.toolsUsed });
    } else {
      let index = this.state.toolsUsed.indexOf(value);
      this.state.toolsUsed.splice(index, 1);
      this.setState({ toolsUsed: this.state.toolsUsed });
    }
  };

  handlePreviousBtnClick = () => {
    if (this.state.currentQuestion > 1) {
      this.setState({ currentQuestion: this.state.currentQuestion - 1 });
    } else {
      this.props.hideModal();
    }
  };

  render() {
    return (
      <section className="question-series-area">
        {this.state.currentQuestion < 12 && (
          <div className="container">
            <div
              className={
                this.state.currentQuestion === 1 ||
                this.state.currentQuestion === 11
                  ? "question-series-wrapper question-instruction-wrapper"
                  : "question-series-wrapper"
              }
            >
              {this.state.currentQuestion === 1 && (
                <div className="question-series-instruction-container">
                  <div className="instruction-1">
                    Hello there, thank you for your interest in signing up as a
                    Virtual Assistant on our platform.
                  </div>
                  <div className="instruction-2">
                    Please answer the following questions so we can match you
                    with the ideal client!
                  </div>
                </div>
              )}
              {this.state.currentQuestion === 2 && (
                <div className="question-1-container">
                  <div className="question-title-container">
                    1. What is your email address?
                  </div>
                  {/* <input
                    className="question-1-input-container"
                    placeholder="Name"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChangeName}
                  ></input> */}
                  <input
                    className="question-1-input-container"
                    placeholder="Email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChangeEmail}
                  ></input>
                </div>
              )}

              {this.state.currentQuestion === 3 && (
                <div className="question-1-container">
                  <div className="question-title-container">
                    2. Which country are you based in?
                  </div>
                  <input
                    className="question-1-input-container"
                    placeholder="Country name"
                    name="country"
                    value={this.state.country}
                    onChange={this.handleChangeCountry}
                  ></input>
                </div>
              )}

              {this.state.currentQuestion === 4 && (
                <div className="question-1-container">
                  <div className="question-title-container">
                    3. Please share your Upwork profile link.
                  </div>
                  <input
                    className="question-1-input-container"
                    placeholder="Upwork profile link"
                    name="profileLink"
                    value={this.state.profileLink}
                    onChange={this.handleChangeProfileLink}
                  ></input>
                </div>
              )}
              {this.state.currentQuestion === 5 && (
                <div className="question-1-container">
                  <div className="question-title-container">
                    4. Since how many years have you been offering virtual
                    assistant services?
                  </div>
                  <div className="radio-btn-group">
                    <label for="5plus">
                      <input
                        type="radio"
                        name="FiveAnswer"
                        onChange={(e) =>
                          this.handleSelectRadioButton(e, "five")
                        }
                        checked={
                          this.state.timePeriod === "5+ years" ? true : false
                        }
                        value="5+ years"
                      />
                      <span class="checkmark"></span>
                      5+ years
                    </label>
                    <label for="3to5">
                      <input
                        type="radio"
                        name="FiveAnswer"
                        onChange={(e) =>
                          this.handleSelectRadioButton(e, "five")
                        }
                        checked={
                          this.state.timePeriod === "3-5 years" ? true : false
                        }
                        value="3-5 years"
                      />
                      <span class="checkmark"></span>
                      3-5 years
                    </label>
                    <label for="1to3">
                      <input
                        type="radio"
                        name="FiveAnswer"
                        onChange={(e) =>
                          this.handleSelectRadioButton(e, "five")
                        }
                        checked={
                          this.state.timePeriod === "1-3 years" ? true : false
                        }
                        value="1-3 years"
                      />
                      <span class="checkmark"></span>
                      1-3 years
                    </label>
                    <label for="0to1">
                      <input
                        type="radio"
                        name="FiveAnswer"
                        onChange={(e) =>
                          this.handleSelectRadioButton(e, "five")
                        }
                        checked={
                          this.state.timePeriod === "0-1 years" ? true : false
                        }
                        value="0-1 years"
                      />
                      <span class="checkmark"></span>
                      0-1 years
                    </label>
                  </div>
                </div>
              )}
              {this.state.currentQuestion === 6 && (
                <div className="question-1-container">
                  <div className="question-title-container">
                    5. How would you rate your written English skills?
                  </div>
                  <div>
                    0 being you need translation, 5 being you are Shakespeare.{" "}
                  </div>
                  <div className="radio-btn-group">
                    <label for="first">
                      <input
                        type="radio"
                        name="SixAnswer"
                        onChange={(e) => this.handleSelectRadioButton(e, "six")}
                        checked={
                          this.state.writtenSkills === "1" ? true : false
                        }
                        value="1"
                      />
                      <span class="checkmark"></span>1
                    </label>
                    <label for="second">
                      <input
                        type="radio"
                        name="SixAnswer"
                        onChange={(e) => this.handleSelectRadioButton(e, "six")}
                        checked={
                          this.state.writtenSkills === "2" ? true : false
                        }
                        value="2"
                      />
                      <span class="checkmark"></span>2
                    </label>
                    <label for="third">
                      <input
                        type="radio"
                        name="SixAnswer"
                        onChange={(e) => this.handleSelectRadioButton(e, "six")}
                        checked={
                          this.state.writtenSkills === "3" ? true : false
                        }
                        value="3"
                      />
                      <span class="checkmark"></span>3
                    </label>
                    <label for="fourth">
                      <input
                        type="radio"
                        name="SixAnswer"
                        onChange={(e) => this.handleSelectRadioButton(e, "six")}
                        checked={
                          this.state.writtenSkills === "4" ? true : false
                        }
                        value="4"
                      />
                      <span class="checkmark"></span>4
                    </label>
                    <label for="fifth">
                      <input
                        type="radio"
                        name="SixAnswer"
                        onChange={(e) => this.handleSelectRadioButton(e, "six")}
                        checked={
                          this.state.writtenSkills === "5" ? true : false
                        }
                        value="5"
                      />
                      <span class="checkmark"></span>5
                    </label>
                  </div>
                </div>
              )}
              {this.state.currentQuestion === 7 && (
                <div className="question-1-container">
                  <div className="question-title-container">
                    6. How would you rate your spoken English skills?
                  </div>
                  <div>
                    0 being you need translation, 5 being you are an expert.{" "}
                  </div>
                  <div className="radio-btn-group">
                    <label for="first1">
                      <input
                        type="radio"
                        name="SeventhAnswer"
                        onChange={(e) =>
                          this.handleSelectRadioButton(e, "seven")
                        }
                        checked={this.state.spokenSkills === "1" ? true : false}
                        value="1"
                      />
                      <span class="checkmark"></span>1
                    </label>
                    <label for="second1">
                      <input
                        type="radio"
                        name="SeventhAnswer"
                        onChange={(e) =>
                          this.handleSelectRadioButton(e, "seven")
                        }
                        checked={this.state.spokenSkills === "2" ? true : false}
                        value="2"
                      />
                      <span class="checkmark"></span>2
                    </label>
                    <label for="third">
                      <input
                        type="radio"
                        name="SeventhAnswer"
                        onChange={(e) =>
                          this.handleSelectRadioButton(e, "seven")
                        }
                        checked={this.state.spokenSkills === "3" ? true : false}
                        value="3"
                      />
                      <span class="checkmark"></span>3
                    </label>
                    <label for="fourth">
                      <input
                        type="radio"
                        name="SeventhAnswer"
                        onChange={(e) =>
                          this.handleSelectRadioButton(e, "seven")
                        }
                        checked={this.state.spokenSkills === "4" ? true : false}
                        value="4"
                      />
                      <span class="checkmark"></span>4
                    </label>
                    <label for="fifth">
                      <input
                        type="radio"
                        name="SeventhAnswer"
                        onChange={(e) =>
                          this.handleSelectRadioButton(e, "seven")
                        }
                        checked={this.state.spokenSkills === "5" ? true : false}
                        value="5"
                      />
                      <span class="checkmark"></span>5
                    </label>
                  </div>
                </div>
              )}

              {this.state.currentQuestion === 8 && (
                <div className="question-1-container">
                  <div className="question-title-container">
                    7. Which of the following functions do you have at least 1+
                    year of experience in?
                  </div>
                  <div className="radio-btn-group">
                    <label for="customer">
                      <input
                        type="checkbox"
                        onChange={this.handleCheckedSpecializations}
                        checked={this.state.specializations.includes(
                          "Customer Service"
                        )}
                        value="Customer Service"
                      />
                      <span class="checkmark"></span>
                      Customer Service
                    </label>
                    <label for="sales">
                      <input
                        type="checkbox"
                        onChange={this.handleCheckedSpecializations}
                        checked={this.state.specializations.includes(
                          "Sales Outreach"
                        )}
                        value="Sales Outreach"
                      />
                      <span class="checkmark"></span>
                      Sales Outreach{" "}
                    </label>
                    <label for="store">
                      <input
                        type="checkbox"
                        onChange={this.handleCheckedSpecializations}
                        checked={this.state.specializations.includes(
                          "E-commerce Store Management"
                        )}
                        value="E-commerce Store Management"
                      />
                      <span class="checkmark"></span>
                      E-commerce Store Management
                    </label>
                    <label for="project">
                      <input
                        type="checkbox"
                        onChange={this.handleCheckedSpecializations}
                        checked={this.state.specializations.includes(
                          "Project Management"
                        )}
                        value="Project Management"
                      />
                      <span class="checkmark"></span>
                      Project Management
                    </label>
                    <label for="email">
                      <input
                        type="checkbox"
                        onChange={this.handleCheckedSpecializations}
                        checked={this.state.specializations.includes(
                          "Email & Calendar Management"
                        )}
                        value="Email & Calendar Management"
                      />
                      <span class="checkmark"></span>
                      Email & Calendar Management
                    </label>
                    <label for="dataentry">
                      <input
                        type="checkbox"
                        onChange={this.handleCheckedSpecializations}
                        checked={this.state.specializations.includes(
                          "Data Entry"
                        )}
                        value="Data Entry"
                      />
                      <span class="checkmark"></span>
                      Data Entry
                    </label>
                    <label for="socialmedia">
                      <input
                        type="checkbox"
                        onChange={this.handleCheckedSpecializations}
                        checked={this.state.specializations.includes(
                          "Social Media Management"
                        )}
                        value="Social Media Management"
                      />
                      <span class="checkmark"></span>
                      Social Media Management
                    </label>
                    <label for="market">
                      <input
                        type="checkbox"
                        onChange={this.handleCheckedSpecializations}
                        checked={this.state.specializations.includes(
                          "Market Research"
                        )}
                        value="Market Research"
                      />
                      <span class="checkmark"></span>
                      Market Research
                    </label>
                    {/* <label for="social">
                      <input
                        type="radio"
                        name="EigthAnswer"
                        onChange={(e) =>
                          this.handleSelectRadioButtonWIthOtherOptions(
                            e,
                            "eight"
                          )
                        }
                        checked={
                          this.state.businessArea ===
                            this.state.businessAreaOthersOption &&
                          this.state.businessAreaOthersOption.length > 0
                            ? true
                            : false
                        }
                        value="Others"
                      />
                      <span class="checkmark"></span>
                      Others:&nbsp;&nbsp;
                      <input
                        className="others-value-input"
                        type="text"
                        disabled={!this.state.isOtherOptionSelected}
                        value={this.state.businessAreaOthersOption}
                        onChange={(e) =>
                          this.handleChangeOtherOptions(e, "three")
                        }
                      ></input>
                    </label> */}
                  </div>
                </div>
              )}

              {this.state.currentQuestion === 9 && (
                <div className="question-1-container">
                  <div className="question-title-container">
                    8. How much do you usually earn every month as a freelancer?
                    (In USD)
                  </div>
                  <input
                    className="question-1-input-container"
                    placeholder="Enter amount"
                    name="amount"
                    value={this.state.salary}
                    onChange={this.handleChangeSalary}
                  ></input>
                </div>
              )}

              {this.state.currentQuestion === 10 && (
                <div className="question-1-container">
                  <div className="question-title-container">
                    9. Which of the following software tools have you used in
                    the past?
                    <div>Select all those you have used for over 3 months.</div>
                  </div>
                  <div className="radio-btn-group">
                    <label for="suite">
                      <input
                        type="checkbox"
                        onChange={this.handleCheckedSoftwareTools}
                        checked={this.state.toolsUsed.includes("Google Suite")}
                        value="Google Suite"
                      />
                      <span class="checkmark"></span>
                      Google Suite
                    </label>
                    <label for="office">
                      <input
                        type="checkbox"
                        onChange={this.handleCheckedSoftwareTools}
                        checked={this.state.toolsUsed.includes(
                          "Microsoft Office"
                        )}
                        value="Microsoft Office"
                      />
                      <span class="checkmark"></span>
                      Microsoft Office{" "}
                    </label>
                    <label for="crm">
                      <input
                        type="checkbox"
                        onChange={this.handleCheckedSoftwareTools}
                        checked={this.state.toolsUsed.includes(
                          "CRM Tools (Salesforce, Zendesk etc)"
                        )}
                        value="CRM Tools (Salesforce, Zendesk etc)"
                      />
                      <span class="checkmark"></span>
                      CRM Tools (Salesforce, Zendesk etc)
                    </label>
                    <label for="accounting">
                      <input
                        type="checkbox"
                        onChange={this.handleCheckedSoftwareTools}
                        checked={this.state.toolsUsed.includes(
                          "Accounting Software (Quickbooks, Xero etc)"
                        )}
                        value="Accounting Software (Quickbooks, Xero etc)"
                      />
                      <span class="checkmark"></span>
                      Accounting Software (Quickbooks, Xero etc)
                    </label>
                  </div>
                </div>
              )}
              {this.state.currentQuestion === 11 && (
                <div className="question-series-instruction-container question-series-instruction-container-custom">
                  <div className="instruction-1">
                    Thank you for your information. You have been registered in
                    our database and our team will be in touch for an assessment
                    before we start connecting you with clients.
                  </div>
                  <div className="instruction-2">
                    Please reach out to amar@ava.work for any queries or
                    feedback.
                  </div>
                  <div
                    className="btn-okay-ctr btn-for-next"
                    onClick={this.handleSignupForm}
                  >
                    <button>Okay</button>
                  </div>
                </div>
              )}
            </div>
            {this.state.currentQuestion < 11 && (
              <div className="question-control-btn-group">
                <div
                  className="btn-for-previous"
                  onClick={
                    this.state.currentQuestion <= 10 &&
                    this.handlePreviousBtnClick
                  }
                >
                  {this.state.currentQuestion <= 10 && (
                    <img
                      src={require("./../../assets/images/questionseries/backbtn.svg")}
                      alt=""
                    />
                  )}
                </div>
                <div className="btn-for-next" onClick={this.handleNextBtnClick}>
                  {!this.state.isCallingForApi ? (
                    <button>
                      {this.state.currentQuestion > 9
                        ? "Next"
                        : `${this.state.currentQuestion}/9`}

                      <img
                        src={require("./../../assets/images/questionseries/nextbtn.svg")}
                        alt=""
                      />
                    </button>
                  ) : (
                    <div className="loader-gif loader-gif-with-padding">
                      <Loader
                        type="ThreeDots"
                        color="#FFFFFF"
                        height="18"
                        width="102"
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            <ToastContainer autoClose={5000} position="bottom-left" />
          </div>
        )}
        <div className="minimize-btn-container" onClick={this.handleSignupForm}>
          <img
            src={require("./../../assets/images/questionseries/minimize.svg")}
            alt=""
          />
        </div>
        {/* {this.state.currentQuestion === 12 && (
          <div className="container calendly-wrapper">
            <InlineWidget
              url="https://calendly.com/amarsbedi/60min"
              styles={{
                position: "relative",
                height: "713px",
              }}
            />
          </div>
        )} */}
      </section>
    );
  }
}

export default QuestionsVa;

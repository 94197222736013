import React, { Component } from "react";
import { Reveal } from "react-reveal/";
import { PopupButton } from "@typeform/embed-react";
import Modal from "./../Common/modal";
import Questionseries from "./../../component/Questionseries/Questionseries";

class frelencerBanner extends Component {
  state = { show: false };

  componentWillMount() {
    localStorage.removeItem("answeredQuestion");
    localStorage.removeItem("show");
  }

  handleRequestOneFreeTask = () => {
    // alert("1");
    if (localStorage.getItem("answeredQuestion") === null) {
      // alert("2");
      this.showModal();
    }
  };

  // Method to show modal, activated when you clicky the OPEN button
  showModal = () => {
    this.setState({ show: true });
  };

  // Method to hide modal, activated by handleClose prop on the <Modal>
  hideModal = () => {
    this.setState({
      show: false,
    });
  };
  render() {
    return (
      <section className="frelencer_banner_area home-bg-color" id="home">
        {/* <div className="home_bubble">
          <div className="bubble b_one"></div>
          <div className="bubble b_two"></div>
          <div className="bubble b_three"></div>
          <div className="bubble b_four"></div>
          <div className="bubble b_five"></div>
          <div className="bubble b_six"></div>
        </div> */}
        <Reveal effect="fadeInRight" duration={500}>
          <img
            className="s_shap"
            src={require("../../image/home-logo/home2.jpeg")}
            alt=""
          />
        </Reveal>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="frelencer_content">
                <Reveal effect="fadeInUp" duration={500}>
                  <h2 className="t_color custom_color">
                    Top 5% Virtual Assistants across Asia
                  </h2>
                </Reveal>
                <Reveal effect="fadeInUp" duration={700}>
                  <h4 className="custom_h4_color">
                    We match you with the best in class VA's,
                    <br />
                    saving you time and money!
                  </h4>
                </Reveal>
                <Reveal effect="fadeInUp" duration={900}>
                  {/* <a
                    href="https://forms.zohopublic.com/ava3/form/BasicSignup/formperma/YBdv9ROrgsLJ7T9VbP-j0PTFYpu-8WQQJMlmdylPQR8"
                    target="_blank"
                    className="theme_btn active"
                  > */}
                  <div
                    onClick={this.handleRequestOneFreeTask}
                    className="theme_btn active"
                  >
                    Get a free task!
                  </div>
                  {/* </a> */}
                </Reveal>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="frelencer_img"></div>
            </div>
          </div>
        </div>
        {localStorage.getItem("answeredQuestion") && !this.state.show && (
          <div className="question-left-container" onClick={this.showModal}>
            {7 - JSON.parse(localStorage.getItem("answeredQuestion")).length > 0
              ? `${
                  7 -
                  JSON.parse(localStorage.getItem("answeredQuestion")).length
                } Questions left to your VA`
              : "Last Question!"}
          </div>
        )}
        <Modal show={this.state.show} handleClose={this.hideModal}>
          <Questionseries hideModal={this.hideModal} />
        </Modal>
      </section>
    );
  }
}

export default frelencerBanner;

import React, { useEffect, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import Loader from "react-loader-spinner";

import CardSection from "./CardSection";

function CardSetupForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  const [isClick, setIsClick] = useState(false);
  const [customerInfo, setCustomerInfo] = useState({});

  useEffect(() => {
    const customerDetails = localStorage.getItem("CustomerDetails");
    setCustomerInfo(customerDetails);
  }, []);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setIsClick(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmCardSetup(props.clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: customerInfo.name,
        },
      },
    });

    if (result.error) {
      props.handleCardMsg(false);
      // Display result.error.message in your UI.
    } else {
      // The setup has succeeded. Display a success message and send
      // result.setupIntent.payment_method to your server to save the
      // card to a Customer
      if (result.setupIntent.status === "succeeded") {
        localStorage.setItem("savedCard", true);
        setIsClick(false);
        props.handleCardMsg(true);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardSection />
      {!isClick ? (
        <button disabled={!stripe}>Save Card</button>
      ) : (
        <div className="customer-details-loader">
          <Loader type="ThreeDots" color="#FFFFFF" height="18" width="102" />
        </div>
      )}
    </form>
  );
}

export default CardSetupForm;

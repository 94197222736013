import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Loader from "react-loader-spinner";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CardSetupForm from "./CardSetupForm";

class CustomerDetails extends Component {
  state = {
    isSubmit: false,
    name: "",
    email: "",
    phone: "",
    isCalledApi: false,
    stripePromiseLoaded: "",
    stripePromiseId: "",
    isCardSavedSucceed: null,
    showCardElement: false,
    showMinimize: true,
    isInitiatePayment: false,
    cutomerInfo: {},
  };

  componentDidMount = () => {
    if (
      localStorage.getItem("CustomerDetails") &&
      localStorage.getItem("savedCard") == null
    ) {
      const stripePromise = loadStripe(
        "pk_test_51JLSLhSCny94Azgo9XNnOxFqRqnp3fDfRYvM00dwVAGNdR6zbv6kqY79xm5LLZhLqcw7dz6pEI3vyXM70xctRpOM00wWbMsClE"
      );
      this.setState({ showCardElement: true });
      this.setState({ stripePromiseLoaded: stripePromise });
      this.setState({ stripePromiseId: localStorage.getItem("ClientSecret") });
    }
    if (
      localStorage.getItem("CustomerDetails") &&
      localStorage.getItem("savedCard")
    ) {
      this.setState({ isInitiatePayment: true });
      this.setState({
        customerInfo: JSON.parse(localStorage.getItem("CustomerDetails")),
      });
    }
  };

  handleSubmitCustomerDetails = () => {
    if (this.validate()) {
      this.setState({ isSubmit: true });
      this.handleApiCall();
    } else {
      this.notify("please fill all the input fields");
    }
  };

  handleApiCall = () => {
    let customerDetails = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
    };
    axios
      .post("https://backend.ava.work/createCustomer", customerDetails)
      .then((res) => {
        console.log(res);
        localStorage.setItem(
          "CustomerDetails",
          JSON.parse(JSON.stringify(res.data.customerDetails))
        );
        localStorage.setItem(
          "ClientSecret",
          JSON.parse(JSON.stringify(res.data.clientSecret))
        );
        if (res.data.id) {
          const stripePromise = loadStripe(
            "pk_test_51JLSLhSCny94Azgo9XNnOxFqRqnp3fDfRYvM00dwVAGNdR6zbv6kqY79xm5LLZhLqcw7dz6pEI3vyXM70xctRpOM00wWbMsClE"
          );
          this.setState({ stripePromiseLoaded: stripePromise });
          this.setState({ stripePromiseId: res.data.clientSecret });
          this.setState({ isCalledApi: true });
          this.setState({ showCardElement: true });
          // this.props.hideModal();
        }
      });
  };

  handleChangeName = (e) => {
    this.setState({ name: e.target.value });
    console.log(e.target.value);
  };

  handleChangeEmail = (e) => {
    this.setState({ email: e.target.value });
    console.log(e.target.value);
  };
  handleChangePhone = (e) => {
    this.setState({ phone: e.target.value });
    console.log(e.target.value);
  };

  notify = (error) => {
    toast.error(error);
  };

  validate = () => {
    var isValid = true;
    if (this.state.name.length === 0) {
      isValid = false;
    }
    if (!this.state.email) {
      isValid = false;
    }
    if (this.state.email) {
      let re = /\S+@\S+\.\S+/;
      isValid = re.test(this.state.email);
    }
    if (this.state.phone.length < 8) {
      isValid = false;
    }
    return isValid;
  };

  handleCardMsg = (isMsg) => {
    this.setState({ showCardElement: false });
    this.setState({ isCardSavedSucceed: isMsg });
    this.setState({ showMinimize: false });
  };

  renderCardSetupForm = () => {
    return (
      <Elements stripe={this.state.stripePromiseLoaded}>
        <CardSetupForm
          clientSecret={this.state.stripePromiseId}
          handleCardMsg={this.handleCardMsg}
        />
      </Elements>
    );
  };

  renderCustomerForm = () => {
    return (
      <div className="question-1-container">
        <div className="question-title-container">
          1. Enter your name, email and phone number?
        </div>
        <input
          className="question-1-input-container"
          placeholder="Name"
          name="name"
          value={this.state.name}
          onChange={this.handleChangeName}
        ></input>
        <input
          className="question-1-input-container"
          placeholder="Email"
          name="email"
          value={this.state.email}
          onChange={this.handleChangeEmail}
        ></input>
        <input
          className="question-1-input-container"
          placeholder="Phone"
          name="phone"
          value={this.state.phone}
          onChange={this.handleChangePhone}
        ></input>
        <div
          className="customer-details-container"
          onClick={this.handleSubmitCustomerDetails}
        >
          {!this.state.isSubmit ? (
            <button>Sign Up</button>
          ) : (
            <div className="customer-details-loader">
              <Loader
                type="ThreeDots"
                color="#FFFFFF"
                height="18"
                width="102"
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  handleRerenderingCardElement = (hideModal) => {
    this.setState({ showMinimize: true });
    this.setState({ showCardElement: true });
    this.setState({ isCardSavedSucceed: null });
    if (hideModal) {
      this.setState({ showCardElement: false });
      this.setState({ isInitiatePayment: true });
      this.setState({
        customerInfo: JSON.parse(localStorage.getItem("CustomerDetails")),
      });
      // this.props.hideModal();
    }
  };

  handleForgetUser = () => {
    localStorage.removeItem("CustomerDetails");
    localStorage.removeItem("ClientSecret");
    localStorage.removeItem("savedCard");
    this.setState({ isInitiatePayment: false });
    this.setState({ isCalledApi: false });
    // this.props.hideModal();
  };

  renderSuccessMsg = () => {
    return (
      <div className="card-save-success-msg-container">
        Card Saved Successfully!!!
        <button onClick={() => this.handleRerenderingCardElement(true)}>
          Okay
        </button>
      </div>
    );
  };

  renderErrorMsg = () => {
    return (
      <div className="card-save-success-msg-container">
        <p>Something went wrong. Please Try Again!!</p>
        <button onClick={() => this.handleRerenderingCardElement(false)}>
          Okay
        </button>
      </div>
    );
  };

  renderPaymentUsingCard = () => {
    return (
      <div className="payment-card-container">
        <p>Welcome back {this.state.customerInfo.name}</p>
        <button>Pay with saved card</button>
        <p className="forget-me" onClick={this.handleForgetUser}>
          Forget me, please!
        </p>
      </div>
    );
  };

  render() {
    return (
      <section className="question-series-area">
        <div className="container">
          {this.state.isInitiatePayment && (
            <div className="card-details-container">
              {this.renderPaymentUsingCard()}
            </div>
          )}
          {!this.state.isCalledApi &&
            localStorage.getItem("CustomerDetails") === null && (
              <div className="question-series-wrapper">
                {this.renderCustomerForm()}
              </div>
            )}
          {this.state.showCardElement && (
            <div className="card-details-container">
              {this.renderCardSetupForm()}
            </div>
          )}
          {this.state.isCardSavedSucceed === true && (
            <div className="card-details-container">
              {this.renderSuccessMsg()}
            </div>
          )}
          {this.state.isCardSavedSucceed === false && (
            <div className="card-details-container">
              {this.renderErrorMsg()}
            </div>
          )}
          <ToastContainer autoClose={5000} position="bottom-left" />
        </div>
        {this.state.showMinimize && (
          <div
            className="minimize-btn-container"
            onClick={() => {
              this.props.hideModal();
            }}
          >
            <img
              src={require("./../../../assets/images/questionseries/minimize.svg")}
              alt=""
            />
          </div>
        )}
      </section>
    );
  }
}

export default CustomerDetails;

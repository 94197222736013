import React, { Component } from "react";
import SectionTitleTwo from "../Banner/SectionTitleTwo";
import { ToastContainer, toast } from "react-toastify";
import Modal from "./../Common/modal";
import CustomerDetails from "./Payment/CustomerDetails";

class BlogTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      isVAHours: false,
      isSupport: false,
      isStarter: false,
      isEntrepreneur: false,
    };
  }

  notify = (error) => {
    toast.error(error);
  };

  // Method to show modal, activated when you clicky the OPEN button
  showModal = () => {
    this.setState({ show: true });
  };

  // Method to hide modal, activated by handleClose prop on the <Modal>
  hideModal = () => {
    this.setState({
      show: false,
    });
  };

  render() {
    return (
      <section className="blog_area_two bg_color" id="blog">
        <div className="container">
          <SectionTitleTwo
            tCenter="text-center"
            stitle="Pricing"
            btitle="No contracts. Only pay for what you use."
            dtitle="Start with your first task free, cancel anytime."
          />
          <div className="pricing-parent-container">
            <div className="pricing-row-1">
              <div className="pricing-col-1"></div>
              <div className="pricing-col-2">
                <div className="pricing-header">Starter Pack</div>
                <div className="pricing-desc">
                  Great if you’re outsourcing work for the first time
                </div>
                <div className="pricing-amount">
                  $125<span>/week</span>
                </div>
              </div>
              <div className="pricing-col-3">
                <div className="pricing-header">Entrepreneur Pack</div>
                <div className="pricing-desc">
                  Essential for those with heavy workloads and experience with
                  Virtual Assistants
                </div>
                <div className="pricing-amount">
                  $200<span>/week</span>
                </div>
              </div>
            </div>
            <div className="pricing-row-2">
              <div className="pricing-col-1">
                <div>VA Hours</div>
                <div
                  className="tooltip-container"
                  onMouseEnter={() => {
                    this.setState({ isVAHours: true });
                  }}
                  onMouseLeave={() => {
                    this.setState({ isVAHours: false });
                  }}
                >
                  <img
                    src={require("./../../image/price-img/tooltip.svg")}
                    alt=""
                  />
                  {this.state.isVAHours && (
                    <div className="tooltip-msg">
                      The minimum time spent by the VA in a week. How you
                      delegate that time is completely up to you!
                    </div>
                  )}
                </div>
              </div>
              <div className="pricing-col-2">
                <div className="pricing-amount-2">20 hours / week</div>
              </div>
              <div className="pricing-col-3">
                <div className="pricing-amount-2">40 hours / week</div>
              </div>
            </div>
            <div className="pricing-row-3">
              <div className="pricing-col-1">
                <div>AVA Support</div>
                <div
                  className="tooltip-container"
                  onMouseEnter={() => {
                    this.setState({ isSupport: true });
                  }}
                  onMouseLeave={() => {
                    this.setState({ isSupport: false });
                  }}
                >
                  <img
                    src={require("./../../image/price-img/tooltip.svg")}
                    alt=""
                  />
                  {this.state.isSupport && (
                    <div className="tooltip-msg">
                      AVA is available to support you through your journey of
                      working with VA’s
                    </div>
                  )}
                </div>
              </div>
              <div className="pricing-col-2">
                <div className="pricing-amount-2">
                  Dedicated Virtual Assistant
                </div>
              </div>
              <div className="pricing-col-3">
                <div className="pricing-amount-2">
                  Dedicated Virtual Assistant <br></br>+<br />
                  Team Lead Support
                </div>
              </div>
            </div>
            <div className="pricing-row-4">
              <div className="pricing-col-1"></div>
              <div className="pricing-col-2">
                <button onClick={() => this.showModal()}>Sign up now!</button>
              </div>
              <div className="pricing-col-3">
                <button onClick={() => this.showModal()}>Sign up now!</button>
              </div>
            </div>
          </div>
          <ToastContainer autoClose={5000} position="bottom-left" />
        </div>
        <Modal show={this.state.show} handleClose={this.hideModal}>
          <CustomerDetails hideModal={this.hideModal} />
        </Modal>
      </section>
    );
  }
}
export default BlogTwo;

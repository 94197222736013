import React, { Component } from "react";
import SectionTitleTwo from "../Banner/SectionTitleTwo";
import profileInfo from "../../component/profileInfo";
import { Reveal } from "react-reveal/";
import Modal from "./../Common/modal";
import Questionseries from "./../../component/Questionseries/Questionseries";
class Profile extends Component {
  state = { show: false };

  componentWillMount() {
    localStorage.removeItem("answeredQuestion");
    localStorage.removeItem("show");
  }

  handleRequestOneFreeTask = () => {
    // alert("1");
    if (localStorage.getItem("answeredQuestion") === null) {
      // alert("2");
      this.showModal();
    }
  };

  // Method to show modal, activated when you clicky the OPEN button
  showModal = () => {
    // alert("working............");
    this.setState({ show: true });
    localStorage.setItem("show", true);
  };

  // Method to hide modal, activated by handleClose prop on the <Modal>
  hideModal = () => {
    this.setState({
      show: false,
    });
    localStorage.setItem("show", false);
  };
  render() {
    return (
      <section className="profile_area bg_color" id="profile">
        <div className="container">
          <SectionTitleTwo
            tCenter="text-center"
            stitle="Pricing"
            btitle="Meet our world class Virtual Assistants"
          />
          <div className="profile-wrapper">
            {profileInfo.map((item) => {
              return (
                <div className="profile-container">
                  <div className="user-img-container">
                    <img
                      src={require("./../../assets/images/Profile-Image/" +
                        `${item.profileImg}`)}
                      alt=""
                    />
                    <p>
                      {item.name}, {item.role}
                    </p>
                  </div>
                  <div className="skills-container">
                    <p>Skills</p>
                    {item.skills.map((skill) => {
                      return <li>{skill.sub}</li>;
                    })}
                  </div>
                  <div className="skills-container">
                    <p>Background</p>
                    {item.background.map((background) => {
                      return <li>{background.key}</li>;
                    })}
                  </div>
                  <div className="skills-container">
                    <p>Superpower</p>
                    <p className="superpower-quotes">{item.superpower}</p>
                  </div>
                  <div className="testimonial-quotes">
                    <p>{item.superpower}</p>
                    <p>
                      <b>-Testimonial</b>
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="frelencer_content profile-btn-container">
            <Reveal effect="fadeInUp" duration={900}>
              {/* <a
                href="https://forms.zohopublic.com/ava3/form/BasicSignup/formperma/YBdv9ROrgsLJ7T9VbP-j0PTFYpu-8WQQJMlmdylPQR8"
                target="_blank"
                className="theme_btn active"
              > */}
              <div
                onClick={this.handleRequestOneFreeTask}
                className="theme_btn active"
              >
                Get a free task!
              </div>
              {/* </a> */}
            </Reveal>
          </div>
        </div>
        <Modal show={this.state.show} handleClose={this.hideModal}>
          <Questionseries hideModal={this.hideModal} />
        </Modal>
        {localStorage.getItem("answeredQuestion") &&
          localStorage.getItem("show") && (
            <div
              className="question-left-container z-index-custom"
              onClick={this.showModal}
            >
              {7 - JSON.parse(localStorage.getItem("answeredQuestion")).length >
              0
                ? `${
                    7 -
                    JSON.parse(localStorage.getItem("answeredQuestion")).length
                  } Questions left to your VA`
                : "Last Question!"}
            </div>
          )}
      </section>
    );
  }
}
export default Profile;

import React, { Component } from "react";

class SectionTitleTwo extends Component {
  render() {
    let { tCenter, stitle, btitle, dtitle, subdtitle } = this.props;
    return (
      <div className={`section_title_two ${tCenter}`}>
        {/* <h6>{stitle}</h6> */}
        <h2>{btitle}</h2>
        {dtitle && (
          <h6 className="dTitleContainer">
            {dtitle}
            <br />
            {subdtitle}
          </h6>
        )}
      </div>
    );
  }
}

export default SectionTitleTwo;

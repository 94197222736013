import React from "react";

export const SuccessPage = () => (
  <div className="body_wrapper">
    <section className="error_area text-center align-items-center d-flex">
      <div className="container">
        <div className="success_text_new">
          <div className="check-mark-success">
            <i class="checkmark">✓</i>
          </div>
          <h3>Payment Successful</h3>
          <p>
            We received your purchase request
            <br /> we'll be in touch shortly!
          </p>
          <a class="back_btn" href="/">
            Go Back to home Page
          </a>
          {/* <h1>404</h1>
          <img src={require("./image/contact.svg")} alt="" /> */}
        </div>
      </div>
    </section>
  </div>
);

const serviceInfo = [
  {
    id: 1,
    image: "inbox.svg",
    serviceName: "Inbox Management",
    desc: [
      {
        keyPoints: "Replying quickly to all of your  messages",
      },
      {
        keyPoints: "Creating automatic email sorting rules",
      },
      {
        keyPoints: "Cleaning out and organizing inboxes",
      },
    ],
  },
  {
    id: 2,
    image: "data.svg",
    serviceName: "Data Entry",
    desc: [
      {
        keyPoints: "Enter data in your CMS, CRM, etc.",
      },
      {
        keyPoints: "Accounting & Bookkeeping",
      },
      {
        keyPoints: "Account Management (cash flow and receipts tracking)",
      },
    ],
  },
  {
    id: 3,
    image: "sales.svg",
    serviceName: "Sales Outreach",
    desc: [
      {
        keyPoints: "Researching sales prospects and  building pipeline",
      },
      {
        keyPoints:
          "Managing sales funnel and following up with clients through the sales journey",
      },
    ],
  },
  {
    id: 4,
    image: "admin.svg",
    serviceName: "Admin Support",
    desc: [
      {
        keyPoints: "HR Admin - onboarding & offboarding employees",
      },
      {
        keyPoints: "Booking appointments and travel",
      },
      {
        keyPoints: "Document storage and organisation",
      },
    ],
  },
  {
    id: 5,
    image: "socail.svg",
    serviceName: "Social Media Management",
    desc: [
      {
        keyPoints: "Set-up social media accounts",
      },
      {
        keyPoints: "Social media engagement",
      },
      {
        keyPoints: "Email campaigns & surveys, blog writing",
      },
    ],
  },
  {
    id: 6,
    image: "customer.svg",
    serviceName: "Customer Service Support",
    desc: [
      {
        keyPoints: "Phone support/ customer service",
      },
      {
        keyPoints: "Respond to email enquiries",
      },
      {
        keyPoints: "Manage Live Chat Tools",
      },
    ],
  },
  {
    id: 7,
    image: "cart.svg",
    serviceName: "E-Commerce Store Management",
    desc: [
      {
        keyPoints: "Create Shopify page",
      },
      {
        keyPoints: "Research e-commerce products",
      },
      {
        keyPoints: "Manage dropshipping",
      },
      {
        keyPoints: "Manage returns, complaints etc",
      },
    ],
  },
  {
    id: 8,
    image: "research.svg",
    serviceName: "Market Research",
    desc: [
      {
        keyPoints: "Target market research",
      },
      {
        keyPoints: "Competitor research",
      },
      {
        keyPoints: "Industry research",
      },
      {
        keyPoints: "SEO keyword research",
      },
      {
        keyPoints: "Social Media research",
      },
    ],
  },
];

export default serviceInfo;

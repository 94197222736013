import React, { Component } from "react";
import SectionTitleTwo from "../component/Banner/SectionTitleTwo";
import { Reveal } from "react-reveal/";
import serviceInfo from "./../component/serviceInfo";
import "./ServiceTwo.css";
class ServiceTwo extends Component {
  state = {
    hoverId: -1,
  };
  render() {
    return (
      <div className="service_area_two" id="service">
        <div className="container">
          <SectionTitleTwo
            // stitle="Our Services"
            btitle="Tasks You can outsource"
            tCenter="text-center"
          />
          <div className="service-parent-container">
            {serviceInfo.map((item) => {
              return (
                <div className="service-cell service-child">
                  <div
                    className="service-child-wrapper"
                    onMouseEnter={() => {
                      this.setState({ hoverId: item.id });
                    }}
                    onMouseLeave={() => {
                      this.setState({ hoverId: -1 });
                    }}
                  >
                    {this.state.hoverId !== item.id && (
                      <div className="service-child-container">
                        <span className="service-logo-container">
                          <img
                            src={require("./../image/service-logo/" +
                              `${item.image}`)}
                            alt=""
                          />
                        </span>
                        <span
                          style={{
                            display: "block",
                            height: "2em",
                            width: "100%",
                          }}
                        ></span>
                        <div className="service-name-container">
                          <h6 style={{ padding: "0px !important" }}>
                            {item.serviceName}
                          </h6>
                        </div>
                      </div>
                    )}
                    {this.state.hoverId === item.id && (
                      <div className="service-child-container">
                        <div className="service-name-container color-white">
                          <h6 style={{ padding: "0px !important" }}>
                            {item.serviceName}
                          </h6>
                        </div>
                        <div className="service-key-points-container">
                          <ul>
                            {item.desc.map((list) => {
                              return <li>{list.keyPoints}</li>;
                            })}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceTwo;

import React from "react";

export const Failed = () => (
  <div className="body_wrapper">
    <section className="error_area text-center align-items-center d-flex">
      <div className="container">
        <div className="error_text_danger">
          <div className="check-mark-danger">
            <img
              src={require("./assets/images/testimonial/paymentfailed.svg")}
              alt=""
            />
          </div>
          <h3>Transaction Failed!</h3>
          <p>
            Please try a different payment method
            <br /> Go to our home page or try to use a search?
          </p>
          <a class="back_btn" href="/">
            Go Back to home Page
          </a>
          {/* <h1>Failed</h1>
          <img src={require("./image/contact.svg")} alt="" /> */}
        </div>
      </div>
    </section>
  </div>
);

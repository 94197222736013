import React, { Component } from "react";
import SectionTitleTwo from "../../component/Banner/SectionTitleTwo";
import Slider from "react-slick";
class PortfolioSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false,
      autoplay: true,
      pauseOnHover: true,
      autoplaySpeed: 5000,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <section className="portfolio_slider_area" id="portfolio">
        <div className="container">
          <SectionTitleTwo
            tCenter="text-center"
            btitle="Our Platform guarantees happy customers! "
            dtitle="WITH A POOL OF 1,000+ PRE-VETTED EXPERTS POSSESSING A WIDE RANGE OF SKILLS."
          />
          <div className="service-parent-container">
            <div className="service-cell slider-child">
              <div className="service-child-wrapper service-child-gap">
                <div className="service-child-container">
                  <span className="slider-logo-container">
                    <img
                      src={require("./../../image/service-logo/trust1.svg")}
                      alt=""
                    />
                  </span>
                  <span
                    style={{ display: "block", height: "2em", width: "100%" }}
                  ></span>
                  <div className="service-icon-name-container">Trust</div>
                  <span
                    style={{ display: "block", height: "1em", width: "100%" }}
                  ></span>
                  <div className="service-name-container-new">
                    <p>
                      With pre-vetting and secure channels of communication, we
                      <b> ensure that your data is trusted and secure.</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-cell slider-child">
              <div className="service-child-wrapper service-child-gap">
                <div className="service-child-container">
                  <span className="slider-logo-container">
                    <img
                      src={require("./../../image/service-logo/quality.svg")}
                      alt=""
                    />
                  </span>
                  <span
                    style={{ display: "block", height: "2em", width: "100%" }}
                  ></span>
                  <div className="service-icon-name-container">Quality</div>
                  <span
                    style={{ display: "block", height: "1em", width: "100%" }}
                  ></span>
                  <div className="service-name-container-new">
                    <p>
                      Ensuring best in class service with
                      <b> VA’s who have the right skills </b>to manage all your
                      needs, efficiently and effectively.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-cell slider-child">
              <div className="service-child-wrapper service-child-gap">
                <div className="service-child-container">
                  <span className="slider-logo-container">
                    <img
                      src={require("./../../image/service-logo/price.svg")}
                      alt=""
                    />
                  </span>
                  <span
                    style={{ display: "block", height: "2em", width: "100%" }}
                  ></span>
                  <div className="service-icon-name-container">Price</div>
                  <span
                    style={{ display: "block", height: "1em", width: "100%" }}
                  ></span>
                  <div className="service-name-container-new">
                    <p>
                      <b>Our pre-negotiated rates </b>enable major cost savings
                      and allow you to focus on what really matters for your
                      business.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <Slider {...settings} className="portfolio_slider">
                        <div className="p_item">
                            <div className="portfolio_content">
                                <a href="./"><img src={require('../../assets/images/FirstPages/microcertificates.png')} alt=""/></a>
                                <div className="text">
                                    <a href="./"><h4>Top Notch Quality</h4></a>
                                    <a href=".#">We find and train only the best</a>
                                </div>
                            </div>
                        </div>
                        <div className="p_item">
                            <div className="portfolio_content">
                                <a href="./"><img src={require('../../assets/images/FirstPages/dig.png')} alt=""/></a>
                                <div className="text">
                                    <a href="./"><h4>Organised &amp; Secure</h4></a>
                                    <a href=".#">Background checks and secure password-sharing tools</a>
                                </div>
                            </div>
                        </div>
                        <div className="p_item">
                            <div className="portfolio_content">
                                <a href="./"><img src={require('../../assets/images/FirstPages/accesstalent.png')} alt=""/></a>
                                <div className="text">
                                    <a href="./"><h4>No contractual commitments</h4></a>
                                    <a href=".#">No long term contracts, billed per usage</a>
                                </div>
                            </div>
                        </div>
                        <div className="p_item">
                            <div className="portfolio_content">
                                <a href="./"><img src={require('../../assets/images/FirstPages/Workshops.png')} alt=""/></a>
                                <div className="text">
                                    <a href="./"><h4>Delegation made easy</h4></a>
                                    <a href=".#">Custom-built tools with in built templates</a>
                                </div>
                            </div>
                        </div>
                    </Slider> */}
        </div>
      </section>
    );
  }
}

export default PortfolioSlider;

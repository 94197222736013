const profileInfo = [
  {
    profileImg: "annie.svg",
    name: "Annie",
    role: "Social Media Marketer",
    skills: [
      {
        sub: "Social Media Management",
      },
      {
        sub: "Content Writing",
      },
      {
        sub: "Facebook Advertising",
      },
    ],
    background: [
      {
        key: "8 years as a social media expert",
      },
      {
        key: "Freelancer since 2015",
      },
    ],
    superpower:
      "Annie is an expert at strategising and executing your social media strategy",
    testimonial:
      "Annie lives and breathes social media. Her strategy has helped us increase our customer loyalty by 15%.",
  },
  {
    profileImg: "jane.svg",
    name: "Jane",
    role: "Admin Support",
    skills: [
      {
        sub: "Data Entry",
      },
      {
        sub: "Internet Research",
      },
      {
        sub: "Customer Service Support",
      },
    ],
    background: [
      {
        key: "10+ years as a virtual assistant",
      },
      {
        key: "Managed team of 30+ in BPO ",
      },
    ],
    superpower:
      "Jane is the perfect person to manage all the nitty grtties of a business",
    testimonial:
      "Jane freed up many hours of mine that allowed me the mental space to think of new growth areas for my  business.",
  },
  {
    profileImg: "jeremy.svg",
    name: "Jeremy",
    role: "E-Commerce Expert",
    skills: [
      {
        sub: "Shopify Website + SEO",
      },
      {
        sub: "Dropshipping",
      },
      {
        sub: "Audience Research",
      },
    ],
    background: [
      {
        key: "5+ years managing Shopify stores",
      },
      {
        key: "Has helped over 100 brands sell online",
      },
    ],
    superpower: "Jeremy has all the answers when it comes to e-commerce",
    testimonial:
      "Jeremy helped me build my e-commerce side hustle into a full fledged business.",
  },
];

export default profileInfo;

import React, { Component } from "react";
import { InlineWidget } from "react-calendly";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Loader from "react-loader-spinner";

class Questionseries extends Component {
  state = {
    currentQuestion: 1,
    answeredQuestion: [],
    isOtherOptionSelected: false,
    name: "",
    email: "",
    specializations: [],
    businessArea: "",
    businessAreaOthersOption: "",
    timePeriod: "",
    commitmentTime: "",
    fromTimePeriod: "",
    language: [],
    languageOthersOption: "",
    isOtherOptionSelectedSeven: false,
    country: "",
    countryOthersOption: "",
    isOtherOptionSelectedEight: false,
    isProjectDescClick: false,
    projectDescription: "",
    isCallingForApi: false,
  };

  handleNextBtnClick = () => {
    if (this.state.currentQuestion === 1) {
      this.handleUpdateState();
    }
    if (this.state.currentQuestion === 2) {
      if (this.validate()) {
        this.handleSendEmail();
        this.handleUpdateState();
      } else {
        this.notify("please fill all the input fields");
      }
    }
    if (this.state.currentQuestion === 3) {
      if (this.state.specializations.length > 0) {
        this.handleUpdateState();
      } else {
        this.notify("please select at least one specializations");
      }
    }
    // if (this.state.currentQuestion === 4) {
    //   // alert(this.state.businessArea);
    //   if (this.state.businessArea.length > 0) {
    //     this.handleUpdateState();
    //   } else {
    //     this.notify("please select at least one businessArea");
    //   }
    // }
    // if (this.state.currentQuestion === 5) {
    //   // alert(this.state.timePeriod);
    //   if (this.state.timePeriod.length > 0) {
    //     this.handleUpdateState();
    //   } else {
    //     this.notify("please select at least one time-period");
    //   }
    // }
    if (this.state.currentQuestion === 4) {
      // alert(this.state.commitmentTime);
      if (this.state.commitmentTime.length > 0) {
        this.handleUpdateState();
      } else {
        this.notify("please select at least one commitment time");
      }
    }
    if (this.state.currentQuestion === 5) {
      // alert(this.state.fromTimePeriod);
      if (this.state.fromTimePeriod.length > 0) {
        this.handleUpdateState();
      } else {
        this.notify("please select at least one time-period");
      }
    }
    if (this.state.currentQuestion === 6) {
      // alert(this.state.language);
      if (this.state.language.length > 0) {
        this.handleUpdateState();
      } else {
        this.notify("please select at least one language");
      }
    }
    if (this.state.currentQuestion === 7) {
      // alert(this.state.country);
      if (this.state.country.length > 0) {
        this.handleUpdateState();
      } else {
        this.notify("please select at least one country");
      }
    }

    // Final Submit Button
    if (this.state.currentQuestion === 8) {
      // alert(this.state.projectDescription);
      this.setState({ isCallingForApi: true });
      // call Api Here
      this.handleSubmitForm();
      // localStorage.removeItem("answeredQuestion");
      // this.setState({ currentQuestion: this.state.currentQuestion + 1 });
    }
    if (this.state.currentQuestion === 9) {
      // alert("working");
      this.setState({ currentQuestion: this.state.currentQuestion + 1 });
    }
  };

  handleSendEmail = () => {
    let userDetails = {
      username: this.state.name,
      email: this.state.email,
    };
    const emailSample = {
      "subject": "Step-1 User Info for searching perfect Virtual Assistant",
      "content": JSON.stringify(userDetails),
      "receiver": "amar@ava.work"
  }
    axios.post("https://backend.ava.work/sendEmail", emailSample);
  };

  handleSubmitForm = () => {
    let userDetails = {
      username: this.state.name,
      email: this.state.email,
      country: this.state.country,
      business: this.state.businessArea,
      vaSpecialisation: this.state.specializations.toString(),
      vaDuration: this.state.timePeriod,
      vaCommitment: this.state.commitmentTime,
      vaStartTime: this.state.fromTimePeriod,
      vaLanguage: this.state.language.toString(),
    };
    const emailSample = {
      "subject": "User Details for searching perfect Virtual Assistant",
      "content": JSON.stringify(userDetails),
      "receiver": "amar@ava.work"
  }
  const emailSampleForCustomer = {
    "subject": "Form Submission for a free task from our expert VA",
    "content": "Thank you for signing up for a free task from our expert Virtual Assistants.\nWe will be in touch within 48 hours with your match.\n\n\n Thanks & Regards \n Team AVA",
    "receiver": this.state.email,
  }
    axios
      .post("https://backend.ava.work/addUser", userDetails)
      .then((res) => this.handleApiResponse(res));
    axios.post("https://backend.ava.work/sendEmail", emailSample);
    axios.post("https://backend.ava.work/sendEmail", emailSampleForCustomer);
  };

  handleApiResponse = (res) => {
    if (res.status === 200) {
      localStorage.removeItem("answeredQuestion");
      this.setState({ currentQuestion: this.state.currentQuestion + 1 });
      this.setState({ isCallingForApi: false });
      this.setState({ name: "" });
      this.setState({ email: "" });
      this.setState({ country: "" });
      this.setState({ businessArea: "" });
      this.setState({ specializations: [] });
      this.setState({ timePeriod: "" });
      this.setState({ commitmentTime: "" });
      this.setState({ fromTimePeriod: "" });
      this.setState({ language: [] });
      this.setState({ answeredQuestion: [] });
      this.setState({ isOtherOptionSelected: false });
      this.setState({ languageOthersOption: "" });
      this.setState({ isOtherOptionSelectedSeven: false });
      this.setState({ countryOthersOption: "" });
      this.setState({ isOtherOptionSelectedEight: false });
      this.setState({ isProjectDescClick: false });
      this.setState({ projectDescription: "" });
      this.setState({ businessAreaOthersOption: "" });
    }
  };

  handleUpdateState = () => {
    this.setState({ currentQuestion: this.state.currentQuestion + 1 });
    this.handleUpdateAnsweredQuestionState();
  };

  handleUpdateAnsweredQuestionState = () => {
    let answeredQuestionArray = this.state.answeredQuestion;
    if (this.state.answeredQuestion.length === 0) {
      this.state.answeredQuestion.push(1);
      this.setState({ answeredQuestion: this.state.answeredQuestion });
    } else {
      let lastAnsweredQuestionNumber = answeredQuestionArray.slice(-1);
      this.state.answeredQuestion.push(lastAnsweredQuestionNumber + 1);
      this.setState({ answeredQuestion: this.state.answeredQuestion });
    }
    localStorage.setItem(
      "answeredQuestion",
      JSON.stringify(this.state.answeredQuestion)
    );
  };

  handlePreviousBtnClick = () => {
    if (this.state.currentQuestion > 1) {
      this.setState({ currentQuestion: this.state.currentQuestion - 1 });
    } else {
      this.props.hideModal();
    }
  };

  handleChangeName = (e) => {
    this.setState({ name: e.target.value });
    console.log(e.target.value);
  };

  handleChangeEmail = (e) => {
    this.setState({ email: e.target.value });
    console.log(e.target.value);
  };

  handleCheckedSpecializations = (e) => {
    const target = e.target;
    var value = target.value;
    if (target.checked) {
      this.state.specializations.push(value);
      this.setState({ specializations: this.state.specializations });
    } else {
      let index = this.state.specializations.indexOf(value);
      this.state.specializations.splice(index, 1);
      this.setState({ specializations: this.state.specializations });
    }
    // alert(this.state.specializations);
    // console.log(this.state.specializations);
  };

  handleCheckedWithOtherOptions = (e) => {
    const target = e.target;
    var value = target.value;
    if (target.checked) {
      if (value !== "Others") {
        this.state.language.push(value);
        this.setState({ language: this.state.language });
      } else {
        this.setState({ isOtherOptionSelectedSeven: true });
      }
    } else {
      if (value !== "Others") {
        let index = this.state.language.indexOf(value);
        this.state.language.splice(index, 1);
        this.setState({ language: this.state.language });
      } else {
        let index = this.state.language.indexOf(
          this.state.languageOthersOption
        );
        this.state.language.splice(index, 1);
        this.setState({ language: this.state.language });
        this.setState({ isOtherOptionSelectedSeven: false });
        this.setState({ languageOthersOption: "" });
      }
    }
  };

  handleSelectRadioButtonWIthOtherOptions = (e, questionNumber) => {
    const target = e.target;
    var value = target.value;
    if (questionNumber === "three") {
      if (target.checked) {
        if (value !== "Others") {
          this.setState({ businessArea: value });
        } else {
          this.setState({ isOtherOptionSelected: true });
        }
      }
    }
    if (questionNumber === "seven") {
      if (target.checked) {
        if (value !== "Others") {
          this.setState({ language: value });
        } else {
          this.setState({ isOtherOptionSelectedSeven: true });
        }
      }
    }
    if (questionNumber === "eight") {
      if (target.checked) {
        if (value !== "Others") {
          this.setState({ country: value });
        } else {
          this.setState({ isOtherOptionSelectedEight: true });
        }
      }
    }
  };

  handleSelectRadioButton = (e, questionNumber) => {
    const target = e.target;
    var value = target.value;
    if (questionNumber === "four") {
      console.log("four");
      if (target.checked) {
        this.setState({ timePeriod: value });
      }
    }
    if (questionNumber === "five") {
      console.log("five");
      if (target.checked) {
        this.setState({ commitmentTime: value });
      }
    }
    if (questionNumber === "six") {
      console.log("six");
      if (target.checked) {
        this.setState({ fromTimePeriod: value });
      }
    }
  };

  handleChangeOtherOptions = (e, questionNumber) => {
    if (questionNumber === "three") {
      this.setState({ businessAreaOthersOption: e.target.value });
      this.setState({ businessArea: e.target.value });
    }
    if (questionNumber === "seven") {
      this.setState({ languageOthersOption: e.target.value });
      this.state.language.push(e.target.value);
      this.setState({ language: this.state.language });
    }
    if (questionNumber === "eight") {
      this.setState({ countryOthersOption: e.target.value });
      this.setState({ country: e.target.value });
    }
  };

  handleProjectDescription = (e) => {
    this.setState({ projectDescription: e.target.value });
  };

  handleUploadProjectFile = (e) => {};

  notify = (error) => {
    toast.error(error);
  };

  validate = () => {
    var isValid = true;
    if (this.state.name.length === 0) {
      isValid = false;
    }
    if (!this.state.email) {
      isValid = false;
    }
    if (this.state.email) {
      let re = /\S+@\S+\.\S+/;
      isValid = re.test(this.state.email);
    }
    return isValid;
  };

  render() {
    return (
      <section className="question-series-area">
        {this.state.currentQuestion < 10 && (
          <div className="container">
            <div
              className={
                this.state.currentQuestion === 1 ||
                this.state.currentQuestion === 9
                  ? "question-series-wrapper question-instruction-wrapper"
                  : "question-series-wrapper"
              }
            >
              {this.state.currentQuestion === 1 && (
                <div className="question-series-instruction-container">
                  <div className="instruction-1">
                    Hello there, you’re one step closer to getting your perfect
                    Virtual Assistant.
                  </div>
                  <div className="instruction-2">
                    We have a series of questions that will take about 3 minutes
                    to complete which will help us understand you better!
                  </div>
                </div>
              )}
              {this.state.currentQuestion === 2 && (
                <div className="question-1-container">
                  <div className="question-title-container">
                    1. What is your name and email?
                  </div>
                  <input
                    className="question-1-input-container"
                    placeholder="Name"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChangeName}
                  ></input>
                  <input
                    className="question-1-input-container"
                    placeholder="Email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChangeEmail}
                  ></input>
                </div>
              )}
              {this.state.currentQuestion === 3 && (
                <div className="question-1-container">
                  <div className="question-title-container">
                    2. Which of the following areas would you like your Virtual
                    Assistant to specialise in?
                  </div>
                  <div className="radio-btn-group">
                    <label for="admin">
                      <input
                        type="checkbox"
                        onChange={this.handleCheckedSpecializations}
                        checked={this.state.specializations.includes(
                          "Admin Support"
                        )}
                        value="Admin Support"
                      />
                      <span class="checkmark"></span>
                      Admin Support
                    </label>
                    <label for="customer">
                      <input
                        type="checkbox"
                        onChange={this.handleCheckedSpecializations}
                        checked={this.state.specializations.includes(
                          "Customer Service Support"
                        )}
                        value="Customer Service Support"
                      />
                      <span class="checkmark"></span>
                      Customer Service Support
                    </label>
                    <label for="dataentry">
                      <input
                        type="checkbox"
                        onChange={this.handleCheckedSpecializations}
                        checked={this.state.specializations.includes(
                          "Data Entry"
                        )}
                        value="Data Entry"
                      />
                      <span class="checkmark"></span>
                      Data Entry
                    </label>
                    <label for="E-Commerce">
                      <input
                        type="checkbox"
                        onChange={this.handleCheckedSpecializations}
                        checked={this.state.specializations.includes(
                          "E-Commerce Management"
                        )}
                        value="E-Commerce Management"
                      />
                      <span class="checkmark"></span>
                      E-Commerce Management
                    </label>
                    <label for="inbox">
                      <input
                        type="checkbox"
                        onChange={this.handleCheckedSpecializations}
                        checked={this.state.specializations.includes(
                          "Inbox Management"
                        )}
                        value="Inbox Management"
                      />
                      <span class="checkmark"></span>
                      Inbox Management
                    </label>
                    <label for="market">
                      <input
                        type="checkbox"
                        onChange={this.handleCheckedSpecializations}
                        checked={this.state.specializations.includes(
                          "Market Research"
                        )}
                        value="Market Research"
                      />
                      <span class="checkmark"></span>
                      Market Research
                    </label>
                    <label for="sales">
                      <input
                        type="checkbox"
                        onChange={this.handleCheckedSpecializations}
                        checked={this.state.specializations.includes(
                          "Sales Outreach"
                        )}
                        value="Sales Outreach"
                      />
                      <span class="checkmark"></span>
                      Sales Outreach
                    </label>
                    <label for="social">
                      <input
                        type="checkbox"
                        onChange={this.handleCheckedSpecializations}
                        checked={this.state.specializations.includes(
                          "Social Media Management"
                        )}
                        value="Social Media Management"
                      />
                      <span class="checkmark"></span>
                      Social Media Management
                    </label>
                  </div>
                </div>
              )}
              {/* {this.state.currentQuestion === 4 && (
                <div className="question-1-container">
                  <div className="question-title-container">
                    3. What area of business are you in?
                  </div>
                  <div className="radio-btn-group">
                    <label for="admin">
                      <input
                        type="radio"
                        name="ThirdAnswer"
                        onChange={(e) =>
                          this.handleSelectRadioButtonWIthOtherOptions(
                            e,
                            "three"
                          )
                        }
                        checked={
                          this.state.businessArea ===
                          "Administration and Business Support"
                            ? true
                            : false
                        }
                        value="Administration and Business Support"
                      />
                      <span class="checkmark"></span>
                      Administration and Business Support
                    </label>
                    <label for="customer">
                      <input
                        type="radio"
                        name="ThirdAnswer"
                        onChange={(e) =>
                          this.handleSelectRadioButtonWIthOtherOptions(
                            e,
                            "three"
                          )
                        }
                        checked={
                          this.state.businessArea === "Agriculture"
                            ? true
                            : false
                        }
                        value="Agriculture"
                      />
                      <span class="checkmark"></span>
                      Agriculture
                    </label>
                    <label for="dataentry">
                      <input
                        type="radio"
                        name="ThirdAnswer"
                        onChange={(e) =>
                          this.handleSelectRadioButtonWIthOtherOptions(
                            e,
                            "three"
                          )
                        }
                        checked={
                          this.state.businessArea ===
                          "Arts, Entertainment and Recreation"
                            ? true
                            : false
                        }
                        value="Arts, Entertainment and Recreation"
                      />
                      <span class="checkmark"></span>
                      Arts, Entertainment and Recreation
                    </label>
                    <label for="E-Commerce">
                      <input
                        type="radio"
                        name="ThirdAnswer"
                        onChange={(e) =>
                          this.handleSelectRadioButtonWIthOtherOptions(
                            e,
                            "three"
                          )
                        }
                        checked={
                          this.state.businessArea === "Construction"
                            ? true
                            : false
                        }
                        value="Construction"
                      />
                      <span class="checkmark"></span>
                      Construction
                    </label>
                    <label for="inbox">
                      <input
                        type="radio"
                        name="ThirdAnswer"
                        onChange={(e) =>
                          this.handleSelectRadioButtonWIthOtherOptions(
                            e,
                            "three"
                          )
                        }
                        checked={
                          this.state.businessArea === "Educational Services"
                            ? true
                            : false
                        }
                        value="Educational Services"
                      />
                      <span class="checkmark"></span>
                      Educational Services
                    </label>
                    <label for="inbox">
                      <input
                        type="radio"
                        name="ThirdAnswer"
                        onChange={(e) =>
                          this.handleSelectRadioButtonWIthOtherOptions(
                            e,
                            "three"
                          )
                        }
                        checked={
                          this.state.businessArea === "Finance and Insurance"
                            ? true
                            : false
                        }
                        value="Finance and Insurance"
                      />
                      <span class="checkmark"></span>
                      Finance and Insurance
                    </label>
                    <label for="sales">
                      <input
                        type="radio"
                        name="ThirdAnswer"
                        onChange={(e) =>
                          this.handleSelectRadioButtonWIthOtherOptions(
                            e,
                            "three"
                          )
                        }
                        checked={
                          this.state.businessArea ===
                          "Healthcare and Social Assistance"
                            ? true
                            : false
                        }
                        value="Healthcare and Social Assistance"
                      />
                      <span class="checkmark"></span>
                      Healthcare and Social Assistance
                    </label>
                    <label for="social">
                      <input
                        type="radio"
                        name="ThirdAnswer"
                        onChange={(e) =>
                          this.handleSelectRadioButtonWIthOtherOptions(
                            e,
                            "three"
                          )
                        }
                        checked={
                          this.state.businessArea ===
                            this.state.businessAreaOthersOption &&
                          this.state.businessAreaOthersOption.length > 0
                            ? true
                            : false
                        }
                        value="Others"
                      />
                      <span class="checkmark"></span>
                      Others:&nbsp;&nbsp;
                      <input
                        className="others-value-input"
                        type="text"
                        disabled={!this.state.isOtherOptionSelected}
                        value={this.state.businessAreaOthersOption}
                        onChange={(e) =>
                          this.handleChangeOtherOptions(e, "three")
                        }
                      ></input>
                    </label>
                  </div>
                </div>
              )} */}
              {/* {this.state.currentQuestion === 5 && (
                <div className="question-1-container">
                  <div className="question-title-container">
                    4. How long do you need the Virtual Assistant?
                  </div>
                  <div className="radio-btn-group">
                    <label for="admin">
                      <input
                        type="radio"
                        name="radAnswer"
                        onChange={(e) =>
                          this.handleSelectRadioButton(e, "four")
                        }
                        checked={
                          this.state.timePeriod === "1 to 3 months"
                            ? true
                            : false
                        }
                        value="1 to 3 months"
                      />
                      <span class="checkmark"></span>1 to 3 months
                    </label>
                    <label for="customer">
                      <input
                        type="radio"
                        name="radAnswer"
                        onChange={(e) =>
                          this.handleSelectRadioButton(e, "four")
                        }
                        checked={
                          this.state.timePeriod === "3 to 6 months"
                            ? true
                            : false
                        }
                        value="3 to 6 months"
                      />
                      <span class="checkmark"></span>3 to 6 months
                    </label>
                    <label for="dataentry">
                      <input
                        type="radio"
                        name="radAnswer"
                        onChange={(e) =>
                          this.handleSelectRadioButton(e, "four")
                        }
                        checked={
                          this.state.timePeriod === "Longer than 6 months"
                            ? true
                            : false
                        }
                        value="Longer than 6 months"
                      />
                      <span class="checkmark"></span>
                      Longer than 6 months
                    </label>
                    <label for="E-Commerce">
                      <input
                        type="radio"
                        name="radAnswer"
                        onChange={(e) =>
                          this.handleSelectRadioButton(e, "four")
                        }
                        checked={
                          this.state.timePeriod === "I have not decided yet"
                            ? true
                            : false
                        }
                        value="I have not decided yet"
                      />
                      <span class="checkmark"></span>I have not decided yet
                    </label>
                  </div>
                </div>
              )} */}
              {this.state.currentQuestion === 4 && (
                <div className="question-1-container">
                  <div className="question-title-container">
                    3. What level of commitment will you require from the
                    Virtual Assistant?
                  </div>
                  <div className="radio-btn-group">
                    <label for="admin">
                      <input
                        type="radio"
                        name="FiveAnswer"
                        onChange={(e) =>
                          this.handleSelectRadioButton(e, "five")
                        }
                        checked={
                          this.state.commitmentTime ===
                          "Full-time (40 or more hours/week)"
                            ? true
                            : false
                        }
                        value="Full-time (40 or more hours/week)"
                      />
                      <span class="checkmark"></span>Full-time (40 or more
                      hours/week)
                    </label>
                    <label for="customer">
                      <input
                        type="radio"
                        name="FiveAnswer"
                        onChange={(e) =>
                          this.handleSelectRadioButton(e, "five")
                        }
                        checked={
                          this.state.commitmentTime ===
                          "Part-time (Less than 40 hours/week)"
                            ? true
                            : false
                        }
                        value="Part-time (Less than 40 hours/week)"
                      />
                      <span class="checkmark"></span>Part-time (Less than 40
                      hours/week)
                    </label>
                    <label for="dataentry">
                      <input
                        type="radio"
                        name="FiveAnswer"
                        onChange={(e) =>
                          this.handleSelectRadioButton(e, "five")
                        }
                        checked={
                          this.state.commitmentTime === "I’ll decide later"
                            ? true
                            : false
                        }
                        value="I’ll decide later"
                      />
                      <span class="checkmark"></span>
                      I’ll decide later
                    </label>
                  </div>
                </div>
              )}
              {this.state.currentQuestion === 5 && (
                <div className="question-1-container">
                  <div className="question-title-container">
                    4. When do you need the Virtual Assistant to start?
                  </div>
                  <div className="radio-btn-group">
                    <label for="admin">
                      <input
                        type="radio"
                        name="SixAnswer"
                        onChange={(e) => this.handleSelectRadioButton(e, "six")}
                        checked={
                          this.state.fromTimePeriod === "Immediately"
                            ? true
                            : false
                        }
                        value="Immediately"
                      />
                      <span class="checkmark"></span>Immediately
                    </label>
                    <label for="customer">
                      <input
                        type="radio"
                        name="SixAnswer"
                        onChange={(e) => this.handleSelectRadioButton(e, "six")}
                        checked={
                          this.state.fromTimePeriod === "In 1 to 2 weeks"
                            ? true
                            : false
                        }
                        value="In 1 to 2 weeks"
                      />
                      <span class="checkmark"></span>In 1 to 2 weeks
                    </label>
                    <label for="dataentry">
                      <input
                        type="radio"
                        name="SixAnswer"
                        onChange={(e) => this.handleSelectRadioButton(e, "six")}
                        checked={
                          this.state.fromTimePeriod ===
                          "More than 2 weeks from now"
                            ? true
                            : false
                        }
                        value="More than 2 weeks from now"
                      />
                      <span class="checkmark"></span>
                      More than 2 weeks from now
                    </label>
                    <label for="E-Commerce">
                      <input
                        type="radio"
                        name="SixAnswer"
                        onChange={(e) => this.handleSelectRadioButton(e, "six")}
                        checked={
                          this.state.fromTimePeriod === "I’ll decide later"
                            ? true
                            : false
                        }
                        value="I’ll decide later"
                      />
                      <span class="checkmark"></span>I’ll decide later
                    </label>
                  </div>
                </div>
              )}
              {this.state.currentQuestion === 6 && (
                <div className="question-1-container">
                  <div className="question-title-container">
                    5. What language(s) would you need the VA to be proficient
                    in?
                  </div>
                  <div className="radio-btn-group">
                    <label for="admin">
                      <input
                        type="checkbox"
                        name="SevenAnswer"
                        onChange={this.handleCheckedWithOtherOptions}
                        checked={this.state.language.includes("English")}
                        value="English"
                      />
                      <span class="checkmark"></span>English
                    </label>
                    <label for="customer">
                      <input
                        type="checkbox"
                        name="SevenAnswer"
                        onChange={this.handleCheckedWithOtherOptions}
                        checked={this.state.language.includes("Chinese")}
                        value="Chinese"
                      />
                      <span class="checkmark"></span>Chinese
                    </label>
                    <label for="dataentry">
                      <input
                        type="checkbox"
                        name="SevenAnswer"
                        onChange={this.handleCheckedWithOtherOptions}
                        checked={this.state.language.includes("Malay")}
                        value="Malay"
                      />
                      <span class="checkmark"></span>
                      Malay
                    </label>
                    <label for="E-Commerce">
                      <input
                        type="checkbox"
                        name="SevenAnswer"
                        onChange={this.handleCheckedWithOtherOptions}
                        checked={this.state.language.includes("Tamil")}
                        value="Tamil"
                      />
                      <span class="checkmark"></span>Tamil
                    </label>
                    <label for="social">
                      <input
                        type="checkbox"
                        name="SevenAnswer"
                        onChange={this.handleCheckedWithOtherOptions}
                        checked={
                          this.state.language.includes(
                            this.state.languageOthersOption
                          ) && this.state.languageOthersOption.length > 0
                            ? true
                            : false
                        }
                        value="Others"
                      />
                      <span class="checkmark"></span>
                      Others:&nbsp;&nbsp;
                      <input
                        className="others-value-input"
                        type="text"
                        disabled={!this.state.isOtherOptionSelectedSeven}
                        value={this.state.languageOthersOption}
                        onChange={(e) =>
                          this.handleChangeOtherOptions(e, "seven")
                        }
                      ></input>
                    </label>
                  </div>
                </div>
              )}
              {this.state.currentQuestion === 7 && (
                <div className="question-1-container">
                  <div className="question-title-container">
                    6. Which country are you currently based in?
                  </div>
                  <div className="radio-btn-group">
                    <label for="admin">
                      <input
                        type="radio"
                        name="EightAnswer"
                        onChange={(e) =>
                          this.handleSelectRadioButtonWIthOtherOptions(
                            e,
                            "eight"
                          )
                        }
                        checked={
                          this.state.country === "Singapore" ? true : false
                        }
                        value="Singapore"
                      />
                      <span class="checkmark"></span>Singapore
                    </label>
                    <label for="customer">
                      <input
                        type="radio"
                        name="EightAnswer"
                        onChange={(e) =>
                          this.handleSelectRadioButtonWIthOtherOptions(
                            e,
                            "eight"
                          )
                        }
                        checked={
                          this.state.country === "Malaysia" ? true : false
                        }
                        value="Malaysia"
                      />
                      <span class="checkmark"></span>Malaysia
                    </label>
                    <label for="dataentry">
                      <input
                        type="radio"
                        name="EightAnswer"
                        onChange={(e) =>
                          this.handleSelectRadioButtonWIthOtherOptions(
                            e,
                            "eight"
                          )
                        }
                        checked={
                          this.state.country === "Indonesia" ? true : false
                        }
                        value="Indonesia"
                      />
                      <span class="checkmark"></span>
                      Indonesia
                    </label>
                    <label for="E-Commerce">
                      <input
                        type="radio"
                        name="EightAnswer"
                        onChange={(e) =>
                          this.handleSelectRadioButtonWIthOtherOptions(
                            e,
                            "eight"
                          )
                        }
                        checked={this.state.country === "China" ? true : false}
                        value="China"
                      />
                      <span class="checkmark"></span>China
                    </label>
                    <label for="social">
                      <input
                        type="radio"
                        name="EightAnswer"
                        onChange={(e) =>
                          this.handleSelectRadioButtonWIthOtherOptions(
                            e,
                            "eight"
                          )
                        }
                        checked={
                          this.state.country ===
                            this.state.countryOthersOption &&
                          this.state.countryOthersOption.length > 0
                            ? true
                            : false
                        }
                        value="Others"
                      />
                      <span class="checkmark"></span>
                      Others:&nbsp;&nbsp;
                      <input
                        className="others-value-input"
                        type="text"
                        disabled={!this.state.isOtherOptionSelectedEight}
                        value={this.state.countryOthersOption}
                        onChange={(e) =>
                          this.handleChangeOtherOptions(e, "eight")
                        }
                      ></input>
                    </label>
                  </div>
                </div>
              )}
              {this.state.currentQuestion === 8 && (
                <div className="question-1-container">
                  <div className="question-title-container">
                    7. Tell us more about your project!
                  </div>
                  <div className="project-textArea-container">
                    <textarea
                      onClick={() => {
                        this.setState({ isProjectDescClick: true });
                      }}
                      onChange={this.handleProjectDescription}
                    ></textarea>
                    <div
                      className={
                        !this.state.isProjectDescClick ||
                        this.state.projectDescription > 0
                          ? "textarea-placeholder"
                          : "textarea-placeholder-hide"
                      }
                    >
                      <p>We would love to know:</p>
                      <ul>
                        <li>What is your project about? </li>
                        <li>
                          Which aspects of the project do you need help with?{" "}
                        </li>
                        <li>Why do you need a virtual assistant? </li>
                        <li className="li-last-container">
                          Not sure what your project is? Just write NIL!
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <div className="project-upload-container">
                    <div className="project-upload-instruction">
                      Feel free to upload any relevant documents to help us
                      understand your needs better
                    </div>
                    <div className="project-input">
                      <input
                        type="file"
                        id="myFile"
                        name="filename"
                        onChange={this.handleUploadProjectFile}
                      ></input>
                      <span>Uploading...</span>
                    </div>
                  </div> */}
                </div>
              )}
              {this.state.currentQuestion === 9 && (
                <div className="question-series-instruction-container">
                  <div className="instruction-1">
                    Thank you for signing up for a free task from our expert
                    Virtual Assistants.
                  </div>
                  <div className="instruction-2">
                    We will be in touch within 48 hours with your match. If you
                    would like to schedule a call with our consultants in the
                    meanwhile, please click next and pick a convenient time in
                    our calendar.
                  </div>
                </div>
              )}
            </div>
            <div className="question-control-btn-group">
              <div
                className="btn-for-previous"
                onClick={
                  this.state.currentQuestion <= 8 && this.handlePreviousBtnClick
                }
              >
                {this.state.currentQuestion <= 8 && (
                  <img
                    src={require("./../../assets/images/questionseries/backbtn.svg")}
                    alt=""
                  />
                )}
              </div>
              <div className="btn-for-next" onClick={this.handleNextBtnClick}>
                {!this.state.isCallingForApi ? (
                  <button>
                    {this.state.currentQuestion > 7
                      ? "Next"
                      : `${this.state.currentQuestion}/7`}

                    <img
                      src={require("./../../assets/images/questionseries/nextbtn.svg")}
                      alt=""
                    />
                  </button>
                ) : (
                  <div className="loader-gif loader-gif-with-padding">
                    <Loader
                      type="ThreeDots"
                      color="#FFFFFF"
                      height="18"
                      width="102"
                    />
                  </div>
                )}
              </div>
            </div>
            <ToastContainer autoClose={5000} position="bottom-left" />
          </div>
        )}
        <div
          className="minimize-btn-container"
          onClick={() => {
            if (this.state.currentQuestion > 10) {
              this.setState({ currentQuestion: 1 });
            }
            this.props.hideModal();
          }}
        >
          <img
            src={require("./../../assets/images/questionseries/minimize.svg")}
            alt=""
          />
        </div>
        {this.state.currentQuestion === 10 && (
          <div className="container calendly-wrapper">
            <InlineWidget
              url="https://calendly.com/amarsbedi/60min"
              styles={{
                position: "relative",
                height: "713px",
              }}
            />
          </div>
        )}
      </section>
    );
  }
}

export default Questionseries;
